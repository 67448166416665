import { useCallback, useContext, useEffect, useState } from 'react';
import type { ProjectConfigModel } from '@frontend/api-wrapper';
import { Page, PaymentsSuccessPayload, Product, UpsellKey, User } from 'types';
import {
  calculatePriceWithCoupon,
  handleError,
  initialize,
  initializeForMultipleProducts,
} from 'utils';
import { useCart, useRouter, useTracking } from 'hooks';
import { PRODUCT_REFUND_KEY } from 'config';
import { UserContext } from 'state';

export const usePayments = (
  config: ProjectConfigModel,
  selectedProduct: Product,
  userData: User,
  redirectToGiftSuccessPage?: boolean,
  sellMultipleProducts?: boolean,
) => {
  const { couponAmount, couponName, couponType } = useContext(UserContext);
  const { cartProducts } = useCart();
  const { push, query } = useRouter();
  const { trackSuccessfulPurchase } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { code } = userData;

  const onSuccess = useCallback(
    async (data: PaymentsSuccessPayload) => {
      await trackSuccessfulPurchase(selectedProduct, code, data.order_id);

      if (selectedProduct.key === PRODUCT_REFUND_KEY) {
        push({
          page: Page.Error,
          queryParams: query,
        });

        return false;
      }

      if (redirectToGiftSuccessPage) {
        window.location.href = 'https://www.getmemowrite.com/gift_success';
      }

      push({
        page: Page.Upsell,
        queryParams: { ...query, upsellKey: UpsellKey.UpsellAdditionalBooks },
      });

      return false;
    },
    [code, selectedProduct, push, query],
  );

  const onReady = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onSubmit = useCallback(() => {
    setErrorMessage('');
  }, []);

  const onError = (err: any) => {
    if (err.data) {
      setErrorMessage(err.data.message);
    } else if (err.message) {
      setErrorMessage(err.message);
    } else {
      setErrorMessage('Error occurred');
    }

    handleError(err);
  };

  useEffect(() => {
    const callbacks = {
      onSuccess,
      onError,
      onSubmit,
      onReady,
    };

    const finalProductPrice = selectedProduct.has_trials
      ? selectedProduct.trials[0].amount
      : selectedProduct.prices[0].final_price;
    const finalPriceWithCoupon = calculatePriceWithCoupon(
      finalProductPrice,
      couponAmount,
      couponType,
    );

    if (sellMultipleProducts) {
      initializeForMultipleProducts(
        config,
        cartProducts as Product[],
        code,
        callbacks,
      );
    } else {
      initialize(
        config,
        selectedProduct,
        code,
        callbacks,
        finalPriceWithCoupon,
        couponName,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    errorMessage,
    selectedProduct,
  };
};
