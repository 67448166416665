import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Product } from 'types';
import { Divider, Icon } from 'components';
import StarsImage from 'assets/images/payments/stars_builder.png';
import StarsSmallImage from 'assets/images/payments/stars_builder_small.png';
import Image from 'next/image';
import { useLocalisation } from 'hooks';

interface Props {
  selectedProduct: Product;
  showCouponDiscount?: boolean;
}

export const OrderSummaryBuild: FC<Props> = () => {
  const { t } = useLocalisation();

  return (
    <Flex
      flexDirection="column"
      flex="1"
      p={{ base: '1rem', lg: '2.25rem 2rem 4rem 1.25rem' }}
      w={{ base: '100%', md: '42.5%' }}
    >
      <Box
        maxW={{ base: '100%', md: '32.5rem' }}
        mx={{ base: 'auto', md: '0' }}
        w="100%"
      >
        <Flex flexDir="column" gap={{ base: '1rem', md: '1.5rem' }}>
          <Text textStyle="L" fontWeight="500" fontFamily="Sentient">
            {t.orderSummary.text1}
          </Text>
          <Flex
            bgColor="white"
            border="0.0625rem solid #DCD5BE"
            borderRadius="0.25rem"
            flexDir="column"
            gap="1rem"
            p={{ base: '1rem', md: '1.5rem' }}
          >
            <Flex alignItems="center" gap="1rem">
              <Text textStyle="XL3" fontFamily="Sentient">
                4.7/5
              </Text>
              <Flex flexDir="column">
                <Flex gap="1rem" alignItems="center">
                  <Text textStyle="body16" fontWeight="600">
                    {t.builderFunnel.excellent}
                  </Text>
                  <Flex maxW="6.75rem">
                    <Image src={StarsImage} />
                  </Flex>
                </Flex>
                <Text
                  textStyle="caption12"
                  sx={{
                    '& > b': {
                      fontWeight: '600',
                      textDecoration: 'underline',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: t.builderFunnel.reviews }}
                />
              </Flex>
            </Flex>
            <Divider />
            <Flex flexDir="column" gap="0.5rem">
              <Text textStyle="body14" fontWeight="700">
                {t.builderFunnel.story}
              </Text>
              <Text textStyle="body14">{t.builderFunnel.comment}</Text>
              <Flex flexDir="column" gap="0.25rem" mt="0.5rem">
                <Flex gap="0.5rem" alignItems="center">
                  <Flex maxW="5.5rem">
                    <Image src={StarsSmallImage} />
                  </Flex>
                  <Flex alignItems="center" gap="0.25rem">
                    <Icon src="CheckmarkGreyFilled" />
                    <Text
                      textStyle="caption12"
                      fontWeight="500"
                      color="#00000099"
                      pt="0.15rem"
                    >
                      {t.upsellMemoryRecall.text4}
                    </Text>
                  </Flex>
                </Flex>
                <Text textStyle="caption12">
                  <Text as="span" fontWeight="700">
                    Terry Levin,
                  </Text>{' '}
                  {t.builderFunnel.date}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
