import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { BuilderType, Product } from 'types';
import { Divider, Icon } from 'components';
import { formatPrice, getCurrency } from 'utils';
import ProductImage from 'assets/images/payments/product_builder.png';
import StarsImage from 'assets/images/payments/stars_builder.png';
import StarsSmallImage from 'assets/images/payments/stars_builder_small.png';
import Image from 'next/image';
import { useCart, useLocalisation } from 'hooks';

interface Props {
  showCouponDiscount?: boolean;
}

export const OrderSummaryBookBuilder: FC<Props> = ({ showCouponDiscount }) => {
  const { cartProducts } = useCart();
  const { formatPriceForLocale, t } = useLocalisation();

  const summaryKeys = ['one', 'two', 'three', 'four', 'five'];

  const selectedProduct = useMemo(() => {
    if (!cartProducts) return undefined;

    return cartProducts.find(p => p.builderType === BuilderType.Plan);
  }, [cartProducts]);

  const currency = useMemo(() => {
    if (!selectedProduct) return '$';

    return getCurrency(selectedProduct.prices[0].currency_id);
  }, [selectedProduct]);

  const getOldPrice = (product?: Product) => {
    if (!product) return 0;

    return product.prices[0].price.toFixed(2);
  };

  const getFinalPrice = (product?: Product) => {
    if (!product) return 0;

    return product.prices[0].final_price.toFixed(2);
  };

  const getTotalPriceWithAdditions = () => {
    if (!cartProducts || !selectedProduct) return 0;

    const selectedProductDiscount = selectedProduct.prices[0].price;

    const additionsPrice = cartProducts.reduce((sum, product) => {
      if (product.key === selectedProduct.key) return sum;

      return sum + (product.prices[0]?.final_price || 0);
    }, 0);

    return (selectedProductDiscount + additionsPrice).toFixed(2);
  };

  const getFinalPriceWithAdditions = () => {
    if (!cartProducts) return 0;

    const totalFinalPrice = cartProducts.reduce((sum, product) => {
      return sum + (product.prices[0]?.final_price || 0);
    }, 0);

    return totalFinalPrice.toFixed(2);
  };

  const discount = useMemo(() => {
    if (!selectedProduct) return null;

    if (selectedProduct.prices[0].discount_percent) {
      const priceDifference = (
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price
      ).toFixed(2);
      const percentage = selectedProduct.prices[0].discount_percent * 100;

      return {
        priceDifference,
        percentage,
      };
    }

    if (
      selectedProduct.prices[0].price !== selectedProduct.prices[0].final_price
    ) {
      const priceDifference =
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price;
      const percentage = (
        (priceDifference / selectedProduct.prices[0].price) *
        100
      ).toFixed(0);

      return {
        priceDifference: priceDifference.toFixed(2),
        percentage,
      };
    }

    return null;
  }, [selectedProduct]);

  return (
    <Flex
      flexDirection="column"
      flex="1"
      p={{ base: '1rem', lg: '2.25rem 2rem 4rem 1.25rem' }}
      w={{ base: '100%', md: '42.5%' }}
    >
      <Box
        maxW={{ base: '100%', md: '32.5rem' }}
        mx={{ base: 'auto', md: '0' }}
        w="100%"
      >
        <Flex flexDir="column" gap={{ base: '1rem', md: '1.5rem' }}>
          <Text textStyle="L" fontWeight="500" fontFamily="Sentient">
            {t.orderSummary.text1}
          </Text>
          <Flex
            bgColor="white"
            border="0.0625rem solid #DCD5BE"
            borderRadius="0.25rem"
            flexDir="column"
            gap="1rem"
            p={{ base: '1rem', md: '1.5rem' }}
          >
            <Flex gap="1rem" alignItems="center">
              <Flex maxW={{ base: '4.75rem', md: '7rem' }}>
                <Image src={ProductImage} />
              </Flex>
              <Flex flexDir="column">
                <Text textStyle="L" fontWeight="500" fontFamily="Sentient">
                  {t.builderFunnel.memoir}
                </Text>
                <Text textStyle="body14" fontWeight="600">
                  {formatPriceForLocale(
                    formatPrice(getFinalPrice(selectedProduct)),
                    currency,
                  )}
                </Text>
                <Text textStyle="body14">{t.builderFunnel.copy}</Text>
              </Flex>
            </Flex>
            <Divider />
            <Accordion allowToggle defaultIndex={0}>
              <AccordionItem border="none">
                <AccordionButton
                  _expanded={{ bg: 'transparent' }}
                  _focus={{ boxShadow: 'none', bg: 'transparent' }}
                  _active={{ bg: 'transparent' }}
                  p={0}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Flex alignItems="center" gap="0.5rem">
                      <Text fontWeight="600" textTransform="uppercase">
                        {t.builderFunnel.whatIncluded}
                      </Text>
                    </Flex>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel p="1.5rem 0 0 0">
                  <Flex flexDir="column" gap="0.75rem">
                    {summaryKeys.map(key => (
                      <Flex key={key} alignItems="center" gap="0.75rem">
                        <Icon src="CheckmarkBlackFilled" />
                        <Text textStyle="body14" fontWeight="500">
                          {t.builderFunnel.summaryItems[key]}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Divider />
            {showCouponDiscount && (
              <>
                {cartProducts?.map(product => {
                  if (product.key === selectedProduct?.key) return null;

                  return (
                    <Flex
                      key={product.key}
                      gap="1rem"
                      justifyContent="space-between"
                    >
                      <Text textStyle="body16">{product.name}</Text>
                      <Text textStyle="body16" fontWeight="500">
                        +
                        {formatPriceForLocale(
                          formatPrice(product.prices[0].final_price),
                          currency,
                        )}
                      </Text>
                    </Flex>
                  );
                })}
                <Flex gap="1rem" justifyContent="space-between">
                  <Flex gap="0.5rem">
                    <Text textStyle="body16">{t.common.coupon} (-35%)</Text>
                    <Flex alignItems="center" gap="0.25rem">
                      <Icon src="LabelGrey" />
                      <Text textStyle="body16">SPRING</Text>
                    </Flex>
                  </Flex>
                  {discount && (
                    <Text textStyle="body16" color="red.700" fontWeight="500">
                      -
                      {formatPriceForLocale(
                        formatPrice(discount.priceDifference),
                        currency,
                      )}
                    </Text>
                  )}
                </Flex>
                <Divider />
              </>
            )}
            <Flex justifyContent="space-between" gap="1rem" alignItems="center">
              <Text textStyle="M" fontWeight="500" fontFamily="Sentient">
                {t.builderFunnel.subtotal}
              </Text>
              <Flex flexDir="column">
                {getOldPrice(selectedProduct) !==
                  getFinalPrice(selectedProduct) && (
                  <Text
                    textStyle="caption12"
                    color="red.700"
                    textDecoration="line-through"
                    textAlign="right"
                    whiteSpace="nowrap"
                  >
                    {formatPriceForLocale(
                      formatPrice(getTotalPriceWithAdditions()),
                      currency,
                    )}
                  </Text>
                )}
                <Text
                  textStyle="M"
                  fontWeight="600"
                  textAlign="right"
                  whiteSpace="nowrap"
                >
                  {formatPriceForLocale(
                    formatPrice(getFinalPriceWithAdditions()),
                    currency,
                  )}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex
              alignItems="center"
              gap="0.5rem"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Text
                textStyle="body14"
                fontWeight="500"
                textTransform="uppercase"
                textAlign="center"
              >
                {t.builderFunnel.guarantee}
              </Text>
              <Text textStyle="body14">&#8226;</Text>
              <Text
                textStyle="body14"
                fontWeight="500"
                textTransform="uppercase"
                textAlign="center"
              >
                {t.builderFunnel.shipping}
              </Text>
            </Flex>
          </Flex>
          <Flex
            bgColor="white"
            border="0.0625rem solid #DCD5BE"
            borderRadius="0.25rem"
            flexDir="column"
            gap="1rem"
            p={{ base: '1rem', md: '1.5rem' }}
          >
            <Flex alignItems="center" gap="1rem">
              <Text textStyle="XL3" fontFamily="Sentient">
                4.7/5
              </Text>
              <Flex flexDir="column">
                <Flex gap="1rem" alignItems="center">
                  <Text textStyle="body16" fontWeight="600">
                    {t.builderFunnel.excellent}
                  </Text>
                  <Flex maxW="6.75rem">
                    <Image src={StarsImage} />
                  </Flex>
                </Flex>
                <Text
                  textStyle="caption12"
                  sx={{
                    '& > b': {
                      fontWeight: '600',
                      textDecoration: 'underline',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: t.builderFunnel.reviews }}
                />
              </Flex>
            </Flex>
            <Divider />
            <Flex flexDir="column" gap="0.5rem">
              <Text textStyle="body14" fontWeight="700">
                {t.builderFunnel.story}
              </Text>
              <Text textStyle="body14">{t.builderFunnel.comment}</Text>
              <Flex flexDir="column" gap="0.25rem" mt="0.5rem">
                <Flex gap="0.5rem" alignItems="center">
                  <Flex maxW="5.5rem">
                    <Image src={StarsSmallImage} />
                  </Flex>
                  <Flex alignItems="center" gap="0.25rem">
                    <Icon src="CheckmarkGreyFilled" />
                    <Text
                      textStyle="caption12"
                      fontWeight="500"
                      color="#00000099"
                      pt="0.15rem"
                    >
                      {t.upsellMemoryRecall.text4}
                    </Text>
                  </Flex>
                </Flex>
                <Text textStyle="caption12">
                  <Text as="span" fontWeight="700">
                    Terry Levin,
                  </Text>{' '}
                  {t.builderFunnel.date}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
