import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import type { Product } from 'types';
import { StorageType, useStorageItem } from 'hooks';
import { StorageKey } from 'types';

export const CartContext = createContext<{
  cartProducts?: Product[];
  setCartProducts: Dispatch<SetStateAction<Product[] | undefined>>;
}>({
  setCartProducts: () => {},
});

type Props = Partial<{
  children: ReactNode;
}>;

export const CartContextProvider: FC<Props> = ({ children }) => {
  const storage = StorageType.SessionStorage;
  const [storedCartProducts, storeCartProducts] = useStorageItem<Product[]>(
    storage,
    StorageKey.Cart,
  );

  const [cartProducts, setCartProducts] = useState<Product[] | undefined>(
    storedCartProducts,
  );

  const value = useMemo(
    () => ({
      cartProducts,
      setCartProducts,
    }),
    [cartProducts],
  );

  useEffect(() => {
    storeCartProducts(cartProducts);
  }, [cartProducts, storeCartProducts]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
