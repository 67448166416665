import { CurrencyId, LanguageLocale, PaymentLocale } from 'types';

export const ENV = process.env.NEXT_PUBLIC_APP_ENV;
export const RND_API_TOKEN = process.env.NEXT_PUBLIC_RND_API_TOKEN as string;
export const RND_API_URL = process.env.NEXT_PUBLIC_RND_API_URL as string;
export const GTM_TOKEN = process.env.NEXT_PUBLIC_GTM_TOKEN;
export const GA4_TOKEN = process.env.NEXT_PUBLIC_GA4_TOKEN;
export const TIKTOK_PIXEL_CODE = process.env
  .NEXT_PUBLIC_TIKTOK_PIXEL_CODE as string;
export const TIKTOK_ACCESS_TOKEN = process.env
  .NEXT_PUBLIC_TIKTOK_ACCESS_TOKEN as string;
export const CHATBASE_TOKEN = process.env.NEXT_PUBLIC_CHATBASE_TOKEN;
export const MOUSEFLOW_TOKEN = process.env.NEXT_PUBLIC_MOUSEFLOW_TOKEN;
export const TIKTOK_PIXEL_TOKEN = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_TOKEN;
export const VWO_ACCOUNT_ID = process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID;
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const APPLOVIN_API_KEY = process.env.NEXT_PUBLIC_APPLOVIN_API_KEY;
export const FACEBOOK_API_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_FACEBOOK_API_ACCESS_TOKEN;
export const REDDIT_PIXEL_ID = process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID;
export const HYROS_TOKEN = process.env.NEXT_PUBLIC_HYROS_TOKEN;
export const SHIPPING_PRICE = 27;
export const MEMOWRITE_APP_URL = 'https://app.getmemowrite.com/';
export const DEFAULT_PRODUCT_IMAGE =
  'https://cdn.shopify.com/s/files/1/0562/9611/2222/files/mwapp_book1_375d86c0-b6de-454d-96c2-fac20a158175.png?v=1712051535';
export const PRODUCT_REFUND_KEY = 'baby-book-99';
export const SUPPORT_EMAIL = 'hello@getmemowrite.com';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const BRAND_NAME = 'Memowrite';

export const LOCALE_TO_CURRENCY_MAP = [
  {
    languages: [LanguageLocale.English],
    paymentLocale: PaymentLocale.English,
    currencyId: CurrencyId.USD,
  },
  {
    languages: [LanguageLocale.Spanish],
    paymentLocale: PaymentLocale.Spanish,
    currencyId: CurrencyId.USD,
  },
  {
    languages: [LanguageLocale.British],
    paymentLocale: PaymentLocale.GreatBritain,
    currencyId: CurrencyId.GBP,
  },
  {
    languages: [LanguageLocale.EuropeanUnion],
    paymentLocale: 'ie',
    currencyId: CurrencyId.EUR,
  },
  {
    languages: [
      LanguageLocale.French,
      LanguageLocale.German,
      LanguageLocale.Italian,
    ],
    paymentLocale: PaymentLocale.Germany,
    currencyId: CurrencyId.EUR,
  },
];
