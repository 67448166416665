import { Flex, Text } from '@chakra-ui/react';
import { useLocalisation, useRouter } from 'hooks';

export const BuildSteps = () => {
  const { t } = useLocalisation();
  const { pathname } = useRouter();

  const isHomePage = pathname === '/[funnel]';

  const items = [
    {
      key: 'email',
      page: 'home',
    },
    {
      key: 'plan',
      page: 'plan',
    },
    {
      key: 'type',
      page: 'book-type',
    },
    {
      key: 'design',
      page: 'book-design',
    },
    {
      key: 'checkout',
      page: 'payment',
    },
  ];

  const shouldHighlight = (page: string) => {
    return page === 'home' && isHomePage ? true : pathname.includes(`/${page}`);
  };

  return (
    <Flex
      p="1rem"
      bgColor="yellow.500"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      gap={{ base: '1rem', md: '2.5rem' }}
      overflowX={{ base: 'auto', md: 'visible' }}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}
    >
      {items.map(({ key, page }, index) => (
        <Flex key={key} alignItems="center" gap="0.75rem">
          <Flex>
            <Flex
              bgColor={shouldHighlight(page) ? 'black' : 'transparent'}
              border="0.0625rem solid"
              borderColor="black"
              w="2rem"
              h="2rem"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              color={shouldHighlight(page) ? 'white' : 'black'}
            >
              {index + 1}
            </Flex>
          </Flex>
          <Text textStyle="body14" fontWeight="500">
            {t.builderFunnel.steps[key]}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};
