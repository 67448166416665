import { useContext } from 'react';
import { CartContext } from 'state';
import { BuilderType, Product } from 'types';

export const useCart = () => {
  const { cartProducts, setCartProducts } = useContext(CartContext);

  const updateCartProducts = (product: Product, builderType: BuilderType) => {
    if (!cartProducts) {
      setCartProducts([{ ...product, builderType }]);

      return;
    }

    const newVal = [
      ...cartProducts?.filter(p => p.builderType !== builderType),
      { ...product, builderType },
    ];

    setCartProducts(newVal);
  };

  const removeCartProduct = (builderType: BuilderType) => {
    const newVal = cartProducts?.filter(p => p.builderType !== builderType);

    setCartProducts(newVal);
  };

  return { cartProducts, removeCartProduct, updateCartProducts };
};
