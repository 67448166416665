import type { FC, ReactNode } from 'react';
import { CartContextProvider, UserContextProvider } from 'state';

interface Props {
  children: ReactNode;
}

export const StateProviders: FC<Props> = ({ children }) => (
  <UserContextProvider>
    <CartContextProvider>{children}</CartContextProvider>
  </UserContextProvider>
);
