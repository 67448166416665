import {
  BRAND_NAME,
  FACEBOOK_API_ACCESS_TOKEN,
  FACEBOOK_PIXEL_ID,
  GTM_TOKEN,
  REDDIT_PIXEL_ID,
  TIKTOK_PIXEL_TOKEN,
} from 'config';
import {
  ConversionsAPIEventName,
  FacebookEventName,
  GtmEventName,
  Product,
  TikTokEventName,
  UserDataProps,
} from 'types';
import { getCookiesByKeys, hashStringToSHA256 } from 'utils';
import { useContext } from 'react';
import { UserContext } from 'state';
import { AppLovinEventName } from 'types/appLovin';

export const useTracking = () => {
  const { user } = useContext(UserContext);

  const constructItemData = (product: Product) => {
    const price = getProductFinalPrice(product);
    const productId = product.sku;
    const productName = product.name;

    return {
      item_id: productId,
      item_name: productName,
      index: 0,
      item_brand: 'Memowrite',
      item_category: 'Digital Subscription',
      price,
      quantity: 1,
    };
  };

  const constructAppLovinItemData = (product: Product) => {
    const price = getProductFinalPrice(product);
    const productId = product.sku;
    const productName = product.name;

    return {
      item_id: productId,
      item_name: productName,
      price,
      item_category_id: 543543,
      quantity: 1,
    };
  };

  const getProductFinalPrice = (product: Product) => {
    return product.trials.length > 0
      ? product.trials[0].amount
      : product.prices[0].final_price;
  };

  const trackGtmEvent = (
    eventName: GtmEventName,
    data?: Record<string, any>,
  ) => {
    if (!GTM_TOKEN) return;

    data
      ? window?.gtag('event', eventName, data)
      : window?.gtag('event', eventName);
  };

  const trackFacebookEvent = (
    eventName: FacebookEventName,
    data?: Record<string, any>,
  ) => {
    if (!FACEBOOK_PIXEL_ID) return;

    data
      ? window?.fbq('track', eventName, data)
      : window?.fbq('track', eventName);
  };

  const trackRedditEvent = (
    eventName: FacebookEventName,
    data?: Record<string, any>,
  ) => {
    if (!REDDIT_PIXEL_ID) return;

    data
      ? window?.rdt('track', eventName, data)
      : window?.rdt('track', eventName);
  };

  const trackConversionsApiEvent = async (
    eventName: ConversionsAPIEventName,
    data?: Record<string, any>,
  ) => {
    if (!FACEBOOK_API_ACCESS_TOKEN || !FACEBOOK_PIXEL_ID) return;

    const timeStamp = Math.round(new Date().getTime() / 1000);
    const currentURL = window.location.href;
    const cookies = getCookiesByKeys(['_fbc', '_fbp']);
    const baseData = {
      event_name: eventName,
      event_time: timeStamp,
      action_source: 'website',
      event_source_url: currentURL,
      data_processing_options_country: 0,
      data_processing_options_state: 0,
    };
    const userData = {
      email: user?.email,
      userCode: user?.code,
    };
    const hashedUserData: UserDataProps = {};
    const userDataKeys = Object.keys(userData);

    for (const key of userDataKeys) {
      const value = userData[key as keyof UserDataProps] || '';
      const hashedValue = await hashStringToSHA256(value);
      Object.assign(hashedUserData, { [key]: hashedValue });
    }

    const payload = {
      data: [
        {
          ...baseData,
          user_data: {
            em: [hashedUserData?.email ?? null],
            fbp: cookies._fbp ?? null,
            fbc: cookies._fbc ?? null,
            client_user_agent: window.navigator.userAgent,
            external_id: [hashedUserData?.userCode ?? null],
          },
          custom_data: data,
        },
      ],
    };

    try {
      await fetch(
        `https://graph.facebook.com/v16.0/${FACEBOOK_PIXEL_ID}/events?access_token=${FACEBOOK_API_ACCESS_TOKEN}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );
    } catch (e) {
      console.error(e);
    }
  };

  const trackTikTokEvents = async (
    event: TikTokEventName,
    payload: any = {},
  ) => {
    if (TIKTOK_PIXEL_TOKEN) {
      window?.ttq?.track(event, payload);
    }
  };

  const trackAppLovinEvents = async (
    event: AppLovinEventName,
    payload: any = {},
  ) => {
    window?.axon?.('track', event, payload);
  };

  const constructPropertiesPayload = (product: Product) => {
    return {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      content_type: 'product',
      description: product?.description || '',
      contents: [
        {
          price: getProductFinalPrice(product),
          quantity: 1,
          content_id: product?.id?.toString(),
          content_name: product?.name,
          content_category: 'Digital',
          brand: BRAND_NAME,
        },
      ],
    };
  };

  const trackAddToCart = (product: Product) => {
    const appLovinData = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructAppLovinItemData(product)],
    };

    trackAppLovinEvents(AppLovinEventName.AddToCart, appLovinData);
  };

  const trackFirstVisit = (product: Product) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructItemData(product)],
    };
    const tikTokPayload = constructPropertiesPayload(product);

    trackGtmEvent(GtmEventName.BeginCheckout, data);
    trackFacebookEvent(FacebookEventName.AddToCart);
    trackFacebookEvent(FacebookEventName.PdpToEmail);
    trackRedditEvent(FacebookEventName.AddToCart);
    trackConversionsApiEvent(ConversionsAPIEventName.InitiateCheckout, data);
    trackTikTokEvents(TikTokEventName.InitiateCheckout, tikTokPayload);
  };

  const trackLeadCreation = (product: Product, email: string) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      email,
    };

    const appLovinData = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructAppLovinItemData(product)],
    };

    trackGtmEvent(GtmEventName.GenerateLead, data);
    trackFacebookEvent(FacebookEventName.Lead);
    trackRedditEvent(FacebookEventName.Lead);
    trackConversionsApiEvent(ConversionsAPIEventName.Lead, data);
    trackTikTokEvents(TikTokEventName.Subscribe);
    trackAppLovinEvents(AppLovinEventName.GenerateLead);
    trackAppLovinEvents(AppLovinEventName.ViewItem, appLovinData);
  };

  const trackAddPaymentsInfo = async (product: Product) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructItemData(product)],
    };
    const tikTokPayload = {
      user: {
        external_id: await hashStringToSHA256(user?.code || ''),
        email: await hashStringToSHA256(user?.email || ''),
      },
      properties: constructPropertiesPayload(product),
    };
    const appLovinData = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructAppLovinItemData(product)],
    };

    trackGtmEvent(GtmEventName.AddPaymentInfo, data);
    trackConversionsApiEvent(ConversionsAPIEventName.AddToCart, data);
    trackTikTokEvents(TikTokEventName.AddPaymentInfo, tikTokPayload);
    trackAppLovinEvents(AppLovinEventName.AddPaymentInfo, appLovinData);
    trackAppLovinEvents(AppLovinEventName.BeginCheckout, appLovinData);
  };

  const trackPaymentAttempt = () => {
    trackGtmEvent(GtmEventName.PaymentAttempt);
  };

  const trackSuccessfulPurchase = async (
    product: Product,
    userCode: string,
    orderId: number,
  ) => {
    const value = getProductFinalPrice(product);
    const productId = product.sku;
    const productName = product.name;
    const data = {
      value,
      currency: product.prices[0].currency_id,
      transaction_id: orderId,
      items: [constructItemData(product)],
    };
    const tikTokPayload = {
      user: {
        external_id: await hashStringToSHA256(user?.code || ''),
        email: await hashStringToSHA256(user?.email || ''),
      },
      properties: constructPropertiesPayload(product),
    };
    const appLovinData = {
      value,
      currency: product.prices[0].currency_id,
      transaction_id: orderId,
      items: [constructAppLovinItemData(product)],
    };

    trackGtmEvent(GtmEventName.Purchase, data);
    trackAppLovinEvents(AppLovinEventName.Purchase, appLovinData);
    trackFacebookEvent(FacebookEventName.Purchase, {
      value: value.toFixed(2),
      currency: product.prices[0].currency_id,
    });
    trackRedditEvent(FacebookEventName.Purchase, {
      products: [
        {
          id: productId,
          name: productName,
          category: productName,
        },
      ],
      conversionId: userCode,
      currency: product.prices[0].currency_id,
      itemCount: 1,
      value: value.toFixed(2),
    });
    trackConversionsApiEvent(ConversionsAPIEventName.Purchase, data);
    trackTikTokEvents(TikTokEventName.CompletePayment, tikTokPayload);
  };

  const trackUpsellItemSelection = () => {
    trackGtmEvent(GtmEventName.SelectItemUpsell);
  };

  const trackUpsellPurchase = async (
    eventName: GtmEventName,
    product: Product,
  ) => {
    const tikTokPayload = {
      user: {
        external_id: await hashStringToSHA256(user?.code || ''),
        email: await hashStringToSHA256(user?.email || ''),
      },
      properties: constructPropertiesPayload(product),
    };

    trackGtmEvent(eventName);
    trackTikTokEvents(TikTokEventName.CompletePayment, tikTokPayload);
  };

  const trackUpsellDecline = (eventName: GtmEventName) => {
    trackGtmEvent(eventName);
  };

  return {
    trackFirstVisit,
    trackLeadCreation,
    trackAddToCart,
    trackAddPaymentsInfo,
    trackPaymentAttempt,
    trackSuccessfulPurchase,
    trackUpsellItemSelection,
    trackUpsellPurchase,
    trackUpsellDecline,
  };
};
