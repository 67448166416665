import type { GatewayProps, Product } from 'types';
import { CouponType } from 'types';
import { cartApi } from 'services';

let isLoaded = false;

const loadScript = (src: any) =>
  new Promise(resolve => {
    if (isLoaded) {
      resolve(null);

      return;
    }

    const element: any = document.createElement('script');
    const attributes = {
      id: `kilo-script-${btoa(src)}`,
      src,
    };

    Object.entries(attributes).forEach(([name, value]: [string, string]) => {
      if (value) {
        element[name] = value;
      }
    });

    element.onload = function () {
      isLoaded = true;
      resolve(null);
    };

    document.head.appendChild(element);
  });

const createUi = async (
  urlConfigs: any,
  product: Product,
  code: string,
  callbacks: any,
  finalPrice: number,
  couponName?: string,
) => {
  try {
    if (typeof (window as any).kiloCheckout !== 'undefined') {
      const paymentProduct = new (window as any).KiloProduct(
        product.key,
        product.name,
        finalPrice,
      );

      if (urlConfigs.prepare_urls) {
        Object.keys(urlConfigs.prepare_urls).map(async url => {
          await (window as any).kiloCheckout.create(
            urlConfigs.prepare_urls[url],
            {
              product: paymentProduct,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks,
            },
          );
        });
      }

      if (couponName) {
        await (window as any).kiloCheckout.setExtraPayload({
          code: couponName,
        });
      }
    }
  } catch (error) {
    callbacks.onError(error);
  }
};

const createUiForMultipleProducts = async (
  urlConfigs: any,
  products: Product[],
  code: string,
  callbacks: any,
) => {
  try {
    if (typeof (window as any).kiloCheckout !== 'undefined') {
      const cart = await cartApi.createCart(code);

      for (const p of products) {
        await cartApi.addItemToCart(cart.id, {
          product_id: p.id,
          quantity: 1,
        });
      }

      const order = await cartApi.convertCartToOrder(code, cart.id);

      const amount = products.reduce((sum, p) => {
        return sum + (p.prices[0]?.final_price || 0);
      }, 0);
      const orderProducts = products.map(p => ({
        key: p.key,
        quantity: 1,
      }));

      const kiloOrder = new (window as any).KiloOrder({
        id: order.id,
        amount,
        amount_in_cents: amount * 100,
        products: orderProducts,
      });

      if (urlConfigs.prepare_urls) {
        Object.keys(urlConfigs.prepare_urls).map(async url => {
          await (window as any).kiloCheckout.create(
            urlConfigs.prepare_urls[url],
            {
              order: kiloOrder,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks,
            },
          );
        });
      }
    }
  } catch (error) {
    callbacks.onError(error);
  }
};

export const initialize = async (
  config: any,
  product: Product,
  code: string,
  callbacks: any,
  finalPrice: number,
  couponName?: string,
) => {
  const urlConfigs: GatewayProps | undefined = config?.gateway;
  const style = document.createElement('link');

  style.setAttribute('type', 'text/css');
  style.setAttribute('rel', 'stylesheet');

  if (urlConfigs && urlConfigs.style) {
    style.setAttribute('href', urlConfigs?.style);
  }

  document.head.appendChild(style);

  if (config.gateway?.main) {
    // eslint-disable-next-line promise/catch-or-return
    loadScript(config.gateway.main).then(() => {
      createUi(
        config.gateway,
        product,
        code,
        callbacks,
        finalPrice,
        couponName,
      );
    });
  }
};

export const initializeForMultipleProducts = async (
  config: any,
  products: Product[],
  code: string,
  callbacks: any,
) => {
  const urlConfigs: GatewayProps | undefined = config?.gateway;
  const style = document.createElement('link');

  style.setAttribute('type', 'text/css');
  style.setAttribute('rel', 'stylesheet');

  if (urlConfigs && urlConfigs.style) {
    style.setAttribute('href', urlConfigs?.style);
  }

  document.head.appendChild(style);

  if (config.gateway?.main) {
    // eslint-disable-next-line promise/catch-or-return
    loadScript(config.gateway.main).then(() => {
      createUiForMultipleProducts(config.gateway, products, code, callbacks);
    });
  }
};

export const replaceLocaleParam = (
  inputObject: Record<string, string>,
  oldLocale: string,
  newLocale: string,
): Record<string, string> => {
  const updatedObject: Record<string, string> = {};

  Object.keys(inputObject).forEach(key => {
    updatedObject[key] = inputObject[key].replace(
      `p_locale=${oldLocale}`,
      `p_locale=${newLocale}`,
    );
  });

  return updatedObject;
};

export const calculatePriceWithCoupon = (
  price: number,
  couponAmount: number | undefined,
  couponType: CouponType | undefined,
) => {
  if (!couponAmount || couponAmount <= 0 || !couponType) return price;

  if (couponType === CouponType.Percent) {
    return price - price * (couponAmount / 100);
  } else if (couponType === CouponType.Amount) {
    return price - couponAmount;
  } else {
    return price;
  }
};
