import { UpsellKey, User } from 'types';

export const getPhotoEnhancerQuantity = (userData: User) => {
  if (!userData.client || !userData.client?.orders) return null;

  const upsellEnhancerOrder = userData.client.orders.find(order =>
    order.items?.find(item => item.product.key.includes('photo-enhancer')),
  );
  const upsellBundleOrder = userData.client.orders.find(order =>
    order.items?.find(item => item.product.key === UpsellKey.UpsellBundle),
  );
  const upsellOrder = upsellBundleOrder || upsellEnhancerOrder;

  if (!upsellOrder) return null;

  const { key } = upsellOrder.items[0].product;

  if (key.includes('50')) return 50;

  if (key.includes('10')) return 50;

  return 'infinite';
};

export const getOrderIds = (userData: User) => {
  if (!userData || !userData.client || !userData.client.orders) return null;

  return userData.client.orders.map(o => o.id).join(', ');
};

export const extractSubscriptionDuration = (userData: User) => {
  if (!userData.client || !userData.client?.orders) return 3;

  const productKey = userData.client.orders[0]?.items[0]?.product?.key;

  if (!productKey) return 3;

  // Check if the product.key contains 'chapters' or 'gift-card'
  if (/chapters|gift-card/i.test(productKey)) return 3;

  // Extract and parse numeric prefix
  const match = productKey.match(/^\d+/);

  return match ? parseInt(match[0], 10) : 3;
};

export const extractProductTextKey = (inputString: string) => {
  const match = inputString.match(/-(\d+|unlimited)$/);

  return match ? match[1] : 'unlimited';
};
