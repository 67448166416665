import type { ParsedUrlQuery } from 'querystring';

export enum Page {
  Home = 'home',
  Email = 'email',
  Error = 'error',
  Payment = 'payment',
  Plan = 'plan',
  BookType = 'book-type',
  BookDesign = 'book-design',
  CoverDesign = 'cover-design',
  CoverType = 'cover-type',
  CoverFinish = 'cover-finish',
  PaperType = 'paper-type',
  InteriorColor = 'interior-color',
  Register = 'register',
  Success = 'success',
  Upsell = 'upsell',
  ThankYou = 'thank-you',
  NotFound = '404',
}

export const Routes: { [key in Page]: { _: string; funnel: string } } = {
  [Page.Home]: { _: '/', funnel: '/[funnel]' },
  [Page.Email]: { _: '/email', funnel: '/[funnel]/email' },
  [Page.Error]: { _: '/error', funnel: '/[funnel]/error' },
  [Page.Payment]: { _: '/payment', funnel: '/[funnel]/payment' },
  [Page.Plan]: { _: '/plan', funnel: '/[funnel]/plan' },
  [Page.BookType]: { _: '/book-type', funnel: '/[funnel]/book-type' },
  [Page.BookDesign]: { _: '/book-design', funnel: '/[funnel]/book-design' },
  [Page.CoverDesign]: { _: '/cover-design', funnel: '/[funnel]/cover-design' },
  [Page.CoverType]: { _: '/cover-type', funnel: '/[funnel]/cover-type' },
  [Page.CoverFinish]: { _: '/cover-finish', funnel: '/[funnel]/cover-finish' },
  [Page.PaperType]: { _: '/paper-type', funnel: '/[funnel]/paper-type' },
  [Page.InteriorColor]: {
    _: '/interior-color',
    funnel: '/[funnel]/interior-color',
  },
  [Page.Register]: { _: '/register', funnel: '/[funnel]/register' },
  [Page.Success]: { _: '/success', funnel: '/[funnel]/success' },
  [Page.Upsell]: { _: '/upsell', funnel: '/[funnel]/upsell' },
  [Page.ThankYou]: { _: '/thank-you', funnel: '/[funnel]/thank-you' },
  [Page.NotFound]: {
    _: '/404',
    funnel: '/[funnel]/404',
  },
};

export interface RouteParams extends ParsedUrlQuery {
  funnel?: string;
}

export interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}
