import { createCartApi, createRndApi } from '@frontend/api-wrapper';
import { RND_API_TOKEN, RND_API_URL } from 'config';

export const RndApi = createRndApi({
  baseUrl: RND_API_URL,
  api_token: RND_API_TOKEN,
});
export const cartApi = createCartApi({
  baseUrl: RND_API_URL,
  api_token: RND_API_TOKEN,
});
